// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "../trix-editor-overrides";

Rails.start();
ActiveStorage.start();

require("@popperjs/core");
// require("@glidejs/glide")
// require("packs/chat")

var Trix = require("trix");
console.log("Config", Trix.config);

const { lang } = Trix.config;

Trix.config.toolbar = {
  getDefaultHTML() {
    return `\
<div class="trix-button-row">
  <p>Add Photo</p>
  <span class="trix-button-group trix-button-group--file-tools" data-trix-button-group="file-tools">
    <button type="button" class="trix-button trix-button--icon trix-button--icon-attach" data-trix-action="attachFiles" title="Attach Photos" tabindex="-1">${lang.attachFiles}</button>
  </span>
</div>\
`;
  },
};

require("@rails/actiontext");

import "bootstrap";
//import "clipboard";
import "@fortawesome/fontawesome-free/js/all";
import "@fortawesome/fontawesome-free/css/all";
import "vanilla-nested";

// require('./nested_forms/add_fields')
// require('./nested_forms/remove_fields')

import Glide, {
  Autoplay,
  Controls,
  Breakpoints,
} from "@glidejs/glide/dist/glide.modular.esm";

import { Tooltip, Modal, Popover } from "bootstrap";

var jQuery = require("jquery");

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

$(document).ready(function () {

  const myModal = new Modal('#welcomeModal')
  myModal.show()

  if ($(".controller_site.action_home .glide").length) {
    new Glide(".controller_site.action_home .glide", {
      autoplay: 8000,
    }).mount({
      Autoplay,
      Controls,
      Breakpoints,
    });
  }
  if ($(".controller_users.action_dashboard .glide .alert").length) {
    new Glide(".controller_users.action_dashboard .glide", {
      autoplay: true,
    }).mount({
      Autoplay,
      Controls,
      Breakpoints,
    });
  }

  $(".copy_to_clipboard").tooltip({
    trigger: "manual",
    placement: "bottom",
    title: "Copied!",
  });

  function setTooltip(btn, message) {
    $(btn).attr("data-original-title", message).tooltip("show");
  }

  function hideTooltip(btn) {
    setTimeout(function () {
      $(btn).tooltip("hide").attr("data-original-title", "");
    }, 1000);
  }

  var clipboard = new ClipboardJS(".copy_to_clipboard");

  clipboard.on("success", function (e) {
    setTooltip(e.trigger, "Copied!");
    hideTooltip(e.trigger);
  });

  var val = parseInt($("#weekly_progress_circle").data("progress-percent"));

  if (val) {
    console.log("here");
    var $circle = $("#svg_progress_circle #bar");
    var $highlight = $("#svg_progress_circle #highlight");

    if (isNaN(val)) {
      val = 100;
    } else {
      var r = $circle.attr("r");
      var c = Math.PI * (r * 2);

      if (val < 0) {
        val = 0;
      }
      if (val > 100) {
        val = 100;
      }

      var pct = ((100 - val) / 100) * c;

      $circle.css({ strokeDashoffset: pct });
      $highlight.css({ strokeDashoffset: pct });

      $("#weekly_progress_circle").attr("data-progress-percent", val);
    }
  }

  // $("input.custom-control-input:checkbox").click(function () {
  //   var consentCheckboxesCount = $(
  //     ".user_consent_checkboxes .custom-control-input"
  //   ).length;
  //   var checkedCheckboxes = $(
  //     ".user_consent_checkboxes .custom-control-input"
  //   ).filter(":checked").length;
  //   if (checkedCheckboxes < consentCheckboxesCount) {
  //     $("button.create_profile_submit_button").prop("disabled", true);
  //   } else {
  //     $("button.create_profile_submit_button").prop("disabled", false);
  //   }
  // });

  $(".ethnicity_other_checkbox").change(function () {
    if (this.checked) {
      $(".ethnicity_other_container").slideDown();
    } else {
      $(".ethnicity_other_container").slideUp();
      $("#user_ethnicity_other").val("");
    }
  });

  $("#user_gender_identity").change(function () {
    var gender_identity = $("#user_gender_identity").val();
    console.log("moo", gender_identity);
    if (gender_identity == "Other") {
      $(".gender_identity_other_container").slideDown();
    } else {
      $(".gender_identity_other_container").slideUp();
      $("#user_gender_identity_other").val("");
    }
  });

  $(".goal_change_other_checkbox").change(function () {
    if (this.checked) {
      $(".goal_change_other_container").slideDown();
    } else {
      $(".goal_change_other_container").slideUp();
      $("#user_goal_goal_change_other").val("");
    }
  });

  $(".goal_heading_container .show_custom_goal_container").click(function () {
    console.log("show_custom_goal_container");
    $(this).parents(".preset_goal_container").hide();
    $(this)
      .parents(".goal_heading_container")
      .find(".custom_goal_container")
      .show();
    $("#user_goal_goal_id").val("");
  });

  $(".goal_heading_container .show_preset_goal_container").click(function () {
    console.log("show_preset_goal_container");
    $(this).parents(".custom_goal_container").hide();
    $(this)
      .parents(".goal_heading_container")
      .find(".preset_goal_container")
      .show();
    $("#user_goal_heading").val("");
  });

  $(document).on("click", ".show_user_step_custom_form", function (e) {
    console.log("show_custom_step_form");
    $(".user_step_custom_form").show();
    $(".show_user_step_custom_form").hide();
    e.preventDefault();
  });

  $(document).on("click", ".cancel_custom_step_button", function (e) {
    console.log("cancel_custom_step_button");
    $(".user_step_custom_form").hide();
    $(".show_user_step_custom_form").show();
    $(".shared_form_errors_partial").hide();
    $(".invalid-feedback").hide();
    $("input").removeClass("is-invalid");
    e.preventDefault();
  });

  $(document).on("click", ".terms_modal_accept_button", function (e) {
    console.log("terms_modal_accept_button");
    $(".open_terms_modal_button").hide();
    $(".terms_accepted_button").show();
    $(".create_profile_submit_button").prop("disabled", false);
    e.preventDefault();
  });

  var delayed_button = $(".enabled_after_delay");
  delayed_button.addClass("disabled");
  $(document).on("click", ".enabled_after_delay", function (e) {
    if (delayed_button.hasClass("disabled")) {
      e.preventDefault();
    }
  });

  setTimeout(function () {
    delayed_button.removeClass("disabled");
  }, 30000);

  $(document).on("click", "#mobile_notifications_menu_trigger", function (e) {
    console.log("mobile_notifications_menu_trigger");
    $(".mobile_notifications_menu").slideToggle();
    e.preventDefault();
  });

  $(document).on("click", ".user_share_content", function (e) {
    console.log("user_share_content");
    var guided_lesson_id = $(this).attr("data-guided-lesson-id");
    console.log("guided_lesson_id", guided_lesson_id);
    $.post("/user_share_content", { guided_lesson_id: guided_lesson_id });
  });

  $('#user_referral').on("change", function () {
    var val = $(this).val();
    console.log(val);

    var referralDetailsValues = ['Organization', 'Social media', 'Other']
    if (referralDetailsValues.includes(val)) {
      console.log('yes');
      $('.referral_details_group').show();
    } else {
      console.log('no');
      $('#user_referral_details').val('')
      $('.referral_details_group').hide();
    }
  });

  $('#age_range_high').on("change", function () {
    var age_range_high = $(this).val();
    var age_range_low = $('#age_range_low').val()

    if (+age_range_high < +age_range_low) {
      $('#age_range_low').val(age_range_high)
    }
  });

  $('#age_range_low').on("change", function () {
    var age_range_low = $(this).val();
    var age_range_high = $('#age_range_high').val()

    if (+age_range_low > +age_range_high) {
      $('#age_range_high').val(age_range_low)
    }
  });
});

// $(window).bind("beforeunload", function () {
//   var data = {
//     url: "this_is_the_url",
//     id: 22,
//   };

//   $.post("/tracking", function (data) {
//     return;
//   });
// });

$(function () {
  // Both of these are from the Bootstrap 5 docs
  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new Tooltip(tooltipTriggerEl);
  });

  var popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]')
  );
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new Popover(popoverTriggerEl);
  });
});

document.addEventListener("DOMContentLoaded", function () {
  // make it as accordion for smaller screens
  if (window.innerWidth < 992) {
    // close all inner dropdowns when parent is closed
    document
      .querySelectorAll(".navbar .dropdown")
      .forEach(function (everydropdown) {
        everydropdown.addEventListener("hidden.bs.dropdown", function () {
          // after dropdown is hidden, then find all submenus
          this.querySelectorAll(".submenu").forEach(function (everysubmenu) {
            // hide every submenu as well
            everysubmenu.style.display = "none";
          });
        });
      });

    document.querySelectorAll(".dropdown-menu a").forEach(function (element) {
      element.addEventListener("click", function (e) {
        let nextEl = this.nextElementSibling;
        if (nextEl && nextEl.classList.contains("submenu")) {
          // prevent opening link if link needs to open dropdown
          e.preventDefault();
          if (nextEl.style.display == "block") {
            nextEl.style.display = "none";
          } else {
            nextEl.style.display = "block";
          }
        }
      });
    });
  }
});
function copyURI(evt) {
  evt.preventDefault();
  navigator.clipboard.writeText(evt.target.getAttribute("href")).then(
    () => {
      /* clipboard successfully set */
    },
    () => {
      /* clipboard write failed */
    }
  );
}

// require("trix")
// require("@rails/actiontext")
import "controllers";

(function () {
  addEventListener("direct-upload:initialize", function (event) {
    var detail, file, id, target;
    target = event.target;
    detail = event.detail;
    id = detail.id;
    file = detail.file;
    return target.insertAdjacentHTML(
      "beforebegin",
      '\n    <div id="direct-upload-' +
        id +
        '" class="direct-upload direct-upload--pending">\n      <div id="direct-upload-progress-' +
        id +
        '" class="direct-upload__progress" style="width: 0%"></div>\n      <span class="direct-upload__filename">' +
        file.name +
        "</span>\n    </div>\n  "
    );
  });

  addEventListener("direct-upload:start", function (event) {
    var element, id;
    id = event.detail.id;
    element = document.getElementById("direct-upload-" + id);
    return element.classList.remove("direct-upload--pending");
  });

  addEventListener("direct-upload:progress", function (event) {
    var _event$detail, id, progress, progressElement;
    _event$detail = event.detail;
    id = _event$detail.id;
    progress = _event$detail.progress;
    progressElement = document.getElementById("direct-upload-progress-" + id);
    return (progressElement.style.width = progress + "%");
  });

  addEventListener("direct-upload:error", function (event) {
    var _event$detail2, element, error, id;
    event.preventDefault();
    _event$detail2 = event.detail;
    id = _event$detail2.id;
    error = _event$detail2.error;
    element = document.getElementById("direct-upload-" + id);
    element.classList.add("direct-upload--error");
    return element.setAttribute("title", error);
  });

  addEventListener("direct-upload:end", function (event) {
    var element, id;
    id = event.detail.id;
    element = document.getElementById("direct-upload-" + id);
    return element.classList.add("direct-upload--complete");
  });
}.call(this));
